exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-create-group-tsx": () => import("./../../../src/pages/create-group.tsx" /* webpackChunkName: "component---src-pages-create-group-tsx" */),
  "component---src-pages-group-tsx": () => import("./../../../src/pages/group.tsx" /* webpackChunkName: "component---src-pages-group-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-license-request-school-tsx": () => import("./../../../src/pages/license-request-school.tsx" /* webpackChunkName: "component---src-pages-license-request-school-tsx" */),
  "component---src-pages-license-request-tsx": () => import("./../../../src/pages/license-request.tsx" /* webpackChunkName: "component---src-pages-license-request-tsx" */),
  "component---src-pages-logged-in-tsx": () => import("./../../../src/pages/logged-in.tsx" /* webpackChunkName: "component---src-pages-logged-in-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-school-tsx": () => import("./../../../src/pages/school.tsx" /* webpackChunkName: "component---src-pages-school-tsx" */),
  "component---src-pages-select-group-tsx": () => import("./../../../src/pages/select-group.tsx" /* webpackChunkName: "component---src-pages-select-group-tsx" */),
  "component---src-pages-unlock-tsx": () => import("./../../../src/pages/unlock.tsx" /* webpackChunkName: "component---src-pages-unlock-tsx" */)
}

