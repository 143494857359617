import Firebase from "firebase/app"

import "firebase/database"
import "firebase/auth"
import "firebase/storage"

const config = {
  apiKey: "AIzaSyD1twD7IcoBDOoBQRjCfcdJzeQyJ-jY8gI",
  authDomain: "runbit-ddf6c.firebaseapp.com",
  databaseURL: "https://runbit-ddf6c.firebaseio.com",
  projectId: "runbit-ddf6c",
  storageBucket: "runbit-ddf6c.appspot.com",
  messagingSenderId: "1038894922436",
}

export const firebaseApp = Firebase.initializeApp(config)
export const db = firebaseApp.database()
export const groupsDb = firebaseApp.database(
  "https://runbit-groups.firebaseio.com"
)
export const auth = firebaseApp.auth()

export const storage = firebaseApp.storage()
export const TIMESTAMP = Firebase.database.ServerValue.TIMESTAMP

export { Firebase }
