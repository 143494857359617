import preval from "babel-plugin-preval/macro"

import { FirebaseAppProvider } from "reactfire"
import StackdriverErrorReporter from "stackdriver-errors-js"

import { firebaseApp } from "./src/firebase"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

/** @type {import('gatsby').GatsbyBrowser['onClientEntry']} */
export function onClientEntry() {
  if (process.env.NODE_ENV == "production") {
    const version =
      process.env.GATSBY_COMMIT ??
      // Avoid putting the whole package.json inside the bundle
      preval`module.exports = require("./package.json").version`

    const errorHandler = new StackdriverErrorReporter()
    errorHandler.start({
      // https://console.cloud.google.com/apis/credentials/key/66ae8ea4-21f0-475a-86e2-c3c0c0b5b395?project=runbit-ddf6c
      key: "AIzaSyBe4V8EUF5hJGA4lN3jTCRdv2sMf5N-Aww",
      projectId: "runbit-ddf6c",
      service: "RunbitWeb",
      version,
    })
  }
}

export function wrapPageElement({ element, props }) {
  return (
    <FirebaseAppProvider firebaseApp={firebaseApp} suspense={true}>
      {element}
    </FirebaseAppProvider>
  )
}
