module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Runbit","short_name":"Runbit","start_url":"/","background_color":"#ED5351","theme_color":"#ED5351","display":"minimal-ui","icon":"src/images/logo.png","prefer_related_applications":true,"related_applications":[{"platform":"play","id":"se.soderstrom.runbit","url":"https://play.google.com/store/apps/details?id=se.soderstrom.runbit"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"803d5b4db6bcb63a4798bff2da24e05b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/runner/work/RunbitWeb/RunbitWeb/src/intl","languages":["en","sv"],"defaultLanguage":"sv","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5SXVKJ8","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
